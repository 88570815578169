<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submit"> Создать </el-button>
    </div>
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Категория" prop="categoryIds">
        <el-select
          v-model="form.categoryIds"
          filterable
          v-el-select-loadmore="loadMoreCategories"
          remote
          :remote-method="searchCategories"
          :loading="categoriesLoading"
          multiple
          placeholder="Выбрать"
        >
          <el-option
            v-for="item in categories"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { transliterate } from '@/helpers/slug'
import { debounce } from '@/helpers/debounce.js'
import { SEO_TYPES } from '@/constants/seo'

import SeoBlock from '@/components/moleculs/SEO.vue'

export default {
  components: { SeoBlock },
  SEO_TYPES,
  mixins: [notifications],
  data() {
    return {
      categories: [],
      categoriesLoading: false,
      categoriesOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      form: {
        name: '',
        slug: '',
        categoryIds: [],
        setDefault: false,
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        categoryIds: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  async created() {
    await this.getCategories()
    this.categoriesSearch = debounce(this.getCategories, 500)
  },
  methods: {
    async getCategories() {
      this.categoriesLoading = true
      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getList(
          this.categoriesOptions,
        )
      this.categoriesLoading = false
      if (error) return

      this.categories = [...this.categories, ...value.data]
      this.categoriesOptions.total = value.meta.count
    },
    loadMoreCategories() {
      const { page, limit, total } = this.categoriesOptions
      if (limit * page < total) {
        this.categoriesOptions.page++
        this.getCategories()
      }
    },
    async searchCategories(search) {
      this.categoriesOptions.page = 1
      this.categoriesOptions.search = search
      this.categoriesLoading = true
      this.categories = []
      await this.categoriesSearch()
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.AddeventServiceCore.AssortmentsActions.create({
            ...this.form,
            setDefault: this.form.setDefault,
            seo: this.form.setDefault
              ? null
              : {
                  ...this.form.seo,
                  type: SEO_TYPES.DEFAULT,
                  ogImage: {
                    ...this.form?.seo?.ogImage,
                    caption: this.form?.seo?.title,
                  },
                },
          })

        loading.close()

        if (error) {
          this.showNotification('Ошибка создания ассортимента', 'error')
          return
        }

        this.showNotification('Ассортимент успешно создан', 'success')
        this.$router.push(ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST)
      })
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }

  .form {
    & > div > label {
      text-align: left;
    }
  }
}
</style>
